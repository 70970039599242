import keyBy from 'lodash/keyBy';

import {Dispatcher} from 'core/entities/Dispatcher/types';
import Driver from 'core/entities/Driver/types';
import Truck from 'core/entities/Truck/types';

import * as types from 'widgets/Chat/redux/actionTypes';
import * as wsTypes from 'widgets/Chat/redux/actionTypes/wsTypes';
import {DriverChannel, MessageStatus} from 'widgets/Chat/types';

import * as mappers from '../mappers/index';

export type DriversChannels = {
    byDriverID: {[key: string]: DriverChannel};
    allIds: string[];
};

export type ChatState = {
    selectedChannelData: {driverID: number | null; truck: Truck | null};
    channels: DriversChannels | {byDriverID: {}; allIds: []};
    allDispatchers: {[key: string]: Dispatcher};
    allDrivers: {[key: string]: Driver};
    clientSocketId: string | null;
    messageStatus: MessageStatus;

    // NEW ↓
    searchParams: {truckNumber: string};
    openedDriversGroup: any;
    messageTextToFill: any;
    driversGroups: any;
    openedTruck: any;
    unreadInfo: any;
    isOpen: boolean;
    dispatcher: any;
};

const initialState: any = {
    selectedChannelData: {driverID: null, truck: null},
    channels: {byDriverID: {}, allIds: []},
    messageStatus: 'idle',
    clientSocketId: null,
    allDispatchers: {},
    allDrivers: {},

    _________________: '_________________', // For convenient debug development

    // NEW ↓
    openedDriversGroup: {tmsUserID: null, attachmentsToMessage: null, isAttachmentsUploading: false},
    driversGroups: {allTmsUserIDs: null, byTmsUserID: null, searchedTmsUserIDs: null},
    messageTextToFill: null,
    searchParams: null,
    openedTruck: null,
    dispatcher: null,
    unreadInfo: null,
    isOpen: false,
};

const chatReducer = (state = initialState, action): any => {
    switch (action.type) {
        case types.TOGGLE_CHAT_WIDGET: {
            return {...state, isOpen: !state.isOpen};
        }

        case types.DRIVERS_GROUPS_RECEIVED: {
            return mappers.handleReducerByDriversGroupsReceived({state, driversGroups: action.payload.driversGroups});
        }

        case types.OPEN_DRIVERS_GROUP: {
            return {
                ...state,
                isOpen: true,
                openedDriversGroup: {...state.openedDriversGroup, tmsUserID: action.payload.tmsUserID},
            };
        }

        case types.TRUCK_FOR_DRIVERS_GROUP_RECEIVED: {
            return {...state, openedTruck: action.payload};
        }

        case types.INIT_MESSAGES_RECEIVED: {
            const {messages, tmsUserID} = action.payload;

            return mappers.handleReducerByInitMessagesReceived({state, messages, tmsUserID});
        }

        case types.DISPATCHER_DATA_RECEIVED: {
            return {...state, dispatcher: action.payload.dispatcher};
        }

        case types.MORE_MESSAGES_RECEIVED: {
            const {messages, tmsUserID} = action.payload;

            return mappers.handleReducerByMoreMessagesReceived({state, messages, tmsUserID});
        }

        case types.LEAVE_DRIVERS_GROUP: {
            return mappers.handleReducerByLeaveDriversGroup({state, tmsUserID: action.payload.tmsUserID});
        }

        case types.UNREAD_DRIVERS_GROUPS_INFO_RECEIVED: {
            return {...state, unreadInfo: keyBy(action.payload.data, 'groupID')};
        }

        case types.SEARCH_PARAMS_RECEIVED: {
            return mappers.handleReducerBySearchParamsReceived({state, formValues: action.payload.formValues});
        }

        case types.SEARCHED_DRIVERS_CLEARED: {
            return {...state, driversGroups: {...state.driversGroups, searchedTmsUserIDs: null}, searchParams: null};
        }

        case types.SET_MESSAGE_STATUS: {
            return {...state, messageStatus: action.payload.messageStatus};
        }

        case types.OPEN_DRIVERS_GROUP_WITH_MESSAGE: {
            const {driverID, messageText} = action.payload;

            return mappers.handleReducerByOpenDriversGroupWithMessage({state, driverID, messageText});
        }

        case types.DRIVERS_GROUP_ALL_MESSAGES_RECEIVED: {
            return mappers.handleReducerByDriversGroupAllMessagesReceived({state, messages: action.payload.messages});
        }

        case types.SET_ATTACHMENTS_UPLOADING: {
            return {
                ...state,
                openedDriversGroup: {...state.openedDriversGroup, isAttachmentsUploading: action.payload},
            };
        }

        case types.ATTACHMENTS_TO_MESSAGE_RECEIVED: {
            return {
                ...state,
                openedDriversGroup: {...state.openedDriversGroup, attachmentsToMessage: action.payload.attachments},
            };
        }

        case wsTypes.WS_CHAT_DISPATCHER_MESSAGE_RECEIVED: {
            return mappers.handleReducerByNewDispatcherMessageReceived({state, message: action.payload.message});
        }

        case wsTypes.WS_CHAT_DRIVER_MESSAGE_RECEIVED: {
            return mappers.handleReducerByNewDriverMessageReceived({state, message: action.payload.message});
        }

        case wsTypes.WEB_SOCKET_CHAT_SET_SOCKET_ID: {
            return {...state, clientSocketId: action.payload.socketId, messageStatus: 'idle'};
        }

        case wsTypes.WEB_SOCKET_CHAT_CLEAR_SOCKET_ID: {
            return {...state, clientSocketId: null, messageStatus: 'idle'};
        }

        case wsTypes.WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE_ERROR: {
            return {...state, messageStatus: 'failure'};
        }

        // old
        // case types.ENTER_TO_DRIVER_CHANNEL: {
        //     const {driverID} = action.payload;

        //     return {
        //         ...state,
        //         selectedChannelData: {
        //             ...state.selectedChannelData,
        //             driverID,
        //         },
        //     };
        // }

        // case types.FETCH_TRUCK_DATA_FOR_DRIVER_CHANNEL: {
        //     const {driverTruckData} = action.payload;

        //     return {
        //         ...state,
        //         selectedChannelData: {
        //             ...state.selectedChannelData,
        //             truck: driverTruckData,
        //         },
        //     };
        // }

        // case types.LEAVE_DRIVER_CHANNEL: {
        //     const sortedChannels = sortChannelsByLastMessageInsertDate(state.channels);

        //     return {
        //         ...state,
        //         channels: sortedChannels,
        //         selectedChannelData: {
        //             driverID: null,
        //             truck: null,
        //         },
        //         searchParams: {},
        //     };
        // }

        // case wsTypes.WEB_SOCKET_CHAT_MORE_CHANNEL_MESSAGES_RECEIVED: {
        //     const {driverID, messages, currentUser} = action.payload;

        //     const currentDriverChannel: DriverChannel = state.channels.byDriverID[driverID];

        //     if (!currentDriverChannel) {
        //         return state;
        //     }

        //     const mappedMessages = messages.map((m) =>
        //         mapMessage(m, state.allDrivers, state.allDispatchers, currentUser.id),
        //     );

        //     const updatedDriverChannel = {
        //         ...currentDriverChannel,
        //         messages: [...mappedMessages, ...currentDriverChannel.messages],
        //     };

        //     return {
        //         ...state,
        //         channels: {
        //             ...state.channels,
        //             byDriverID: {...state.channels.byDriverID, [driverID]: updatedDriverChannel},
        //         },
        //     };
        // }

        // case wsTypes.WEB_SOCKET_CHAT_MESSAGE_RECEIVED: {
        //     // const channelWithNewMessage = action.payload;
        //     //
        //     // if (!channelWithNewMessage) {
        //     //     return {...state, messageStatus: 'failure'};
        //     // }
        //     //
        //     // const {
        //     //     message: {driverID, newMessage, lastMessageInsertDate},
        //     //     currentUser,
        //     // } = channelWithNewMessage;
        //     //
        //     // if (!driverID || !newMessage) {
        //     //     return {...state, messageStatus: 'failure'};
        //     // }
        //     //
        //     // const currentDriverChannel: DriverChannel = state.channels.byDriverID[driverID];
        //     //
        //     // if (!currentDriverChannel) {
        //     //     return {...state, messageStatus: 'failure'};
        //     // }
        //     //
        //     // // temp solution - in some cases and not for all OS(windows, mac) after re login without reload page
        //     // // socket io starts send added message to sender and we have bug with duplicated messages
        //     // // for avoid this issue we check existing new message in current channel
        //     // const isNewMessageExistsInChannel = currentDriverChannel.messages.some(
        //     //     (message) => message.id === newMessage.id,
        //     // );
        //     //
        //     // if (isNewMessageExistsInChannel) {
        //     //     return {...state, messageStatus: 'failure'};
        //     // }
        //     //
        //     // const newMessageWithMappedData = mapMessage(
        //     //     newMessage,
        //     //     state.allDrivers,
        //     //     state.allDispatchers,
        //     //     currentUser.id,
        //     // );
        //     //
        //     // const updatedDriverChannel = {
        //     //     ...currentDriverChannel,
        //     //     // while driver doesnt have messages his channel will be without id, so after we get first message we add channel id
        //     //     channelID:
        //     //         currentDriverChannel.channelID === undefined
        //     //             ? newMessage.channelID
        //     //             : currentDriverChannel.channelID,
        //     //     lastMessageInsertDate,
        //     //     messages: [...currentDriverChannel.messages, newMessageWithMappedData],
        //     //     unreadMessagesCount: newMessageWithMappedData.driver
        //     //         ? currentDriverChannel.unreadMessagesCount + 1
        //     //         : currentDriverChannel.unreadMessagesCount,
        //     // };
        //     //
        //     // const currentChannels = {
        //     //     ...state.channels,
        //     //     byDriverID: {...state.channels.byDriverID, [driverID]: updatedDriverChannel},
        //     // };
        //     //
        //     // const sortedDriversChannels = sortChannelsByLastMessageInsertDate(currentChannels);
        //
        //     return {
        //         ...state,
        //         messageStatus: 'success',
        //         // channels: sortedDriversChannels,
        //     };
        // }

        case wsTypes.WEB_SOCKET_CHAT_MARK_LOCAL_MESSAGES_AS_READ: {
            const {driverID} = action.payload;

            const channel: DriverChannel = state.channels.byDriverID[driverID];

            if (!channel || channel.unreadMessagesCount === 0) {
                return state;
            }

            const readMessages = channel.messages.map((msg) => ({...msg, isUnread: false}));

            return {
                ...state,
                channels: {
                    ...state.channels,
                    byDriverID: {
                        ...state.channels.byDriverID,
                        [driverID]: {...channel, unreadMessagesCount: 0, messages: readMessages},
                    },
                },
            };
        }

        default:
            return {...state};
    }
};

export default chatReducer;
