import {useDispatch} from 'react-redux';

import * as actions from 'widgets/Chat/redux/actions';
import * as wsActions from 'widgets/Chat/redux/actions/wsActions';

import {DriverSearchFormValues, MessageFormValues} from '../types/form';

type UseChatActions = {
    sendMessageByDispatcherToDriversGroup: (params: {message: Partial<MessageFormValues>}) => void;
    searchDriverByTruckNumber: (params: {formValues: Partial<DriverSearchFormValues>}) => void;
    checkIsAvailableDriversGroupByDriverID: (params: {driverID: number}) => any;
    getDriversGroupInitLatestMessages: (params: {tmsUserID: number}) => void;
    getUnreadMessagesAmountByDriverID: (params: {driverID: number}) => any;
    sendAttachmentsByUpload: (params: {fileList: FileList | null}) => void;
    websocketConnectionReceived: (params: {webSocket: WebSocket}) => void;
    getDriversGroupMoreMessages: (params: {tmsUserID: number}) => void;
    getDriversGroupAllMessages: (params: {tmsUserID: number}) => void;
    openDriversGroupByDriverID: (params: {driverID: number}) => void;
    getTruckForDriversGroup: (params: {tmsUserID: number}) => void;
    leaveDriversGroup: (params: {tmsUserID: number}) => void;
    openDriversGroup: (params: {tmsUserID: number}) => void;
    getDriversGroupsCommonUnreadInfo: () => void;
    clearSearchedDrivers: () => void;
    getDriversGroups: () => void;
    chatWSConnect: () => void;
    toggleChat: () => void;
    init: () => void;
};

const useChatActions = (): UseChatActions => {
    const dispatch = useDispatch();

    return {
        getDriversGroupInitLatestMessages: (params) => dispatch(actions.getDriversGroupInitLatestMessages(params)),
        getUnreadMessagesAmountByDriverID: (params) => dispatch(actions.getUnreadMessagesAmountByDriverID(params)),
        websocketConnectionReceived: (params) => dispatch(wsActions.websocketConnectionReceived(params)),
        getDriversGroupMoreMessages: (params) => dispatch(actions.getDriversGroupMoreMessages(params)),
        getDriversGroupAllMessages: (params) => dispatch(actions.getDriversGroupAllMessages(params)),
        getDriversGroupsCommonUnreadInfo: () => dispatch(actions.getDriversGroupsCommonUnreadInfo()),
        openDriversGroupByDriverID: (params) => dispatch(actions.openDriversGroupByDriverID(params)),
        searchDriverByTruckNumber: (params) => dispatch(actions.searchDriverByTruckNumber(params)),
        getTruckForDriversGroup: (params) => dispatch(actions.getTruckForDriversGroup(params)),
        sendAttachmentsByUpload: (params) => dispatch(actions.sendAttachmentsByUpload(params)),
        leaveDriversGroup: (params) => dispatch(actions.leaveDriversGroup(params)),
        openDriversGroup: (params) => dispatch(actions.openDriversGroup(params)),
        clearSearchedDrivers: () => dispatch(actions.clearSearchedDrivers()),
        toggleChat: () => dispatch(actions.chatActionCreators.toggleChat()),
        checkIsAvailableDriversGroupByDriverID: (params) =>
            dispatch(actions.checkIsAvailableDriversGroupByDriverID(params)),
        sendMessageByDispatcherToDriversGroup: (params) =>
            dispatch(actions.sendMessageByDispatcherToDriversGroup(params)),
        getDriversGroups: () => dispatch(actions.getDriversGroups()),
        chatWSConnect: () => dispatch(wsActions.chatWSConnect()),
        init: () => dispatch(actions.init()),
    };
};

export default useChatActions;
