import {getUserAccessToken} from 'store/reducers/auth/selectors';

// import {getCurrentUser} from 'store/reducers/userData/selectors';
import appConfig from 'config';

import * as wsRequests from 'services/chatApi/websocket';

import * as wsTypes from 'widgets/Chat/redux/actionTypes/wsTypes';
import {WebsocketMessageResponse} from 'widgets/Chat/types/message';

export const sendChatMessage = (message) => ({type: wsTypes.WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE, payload: message});
export const clearSocketID = () => ({type: wsTypes.WEB_SOCKET_CHAT_CLEAR_SOCKET_ID});
export const chatDisconnect = () => ({type: wsTypes.WEB_SOCKET_CHAT_DISCONNECT});

export const chatMessageSendError = (error) => ({
    type: wsTypes.WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE_ERROR,
    payload: error,
});

export const setWebSocketID = ({webSocket}) => ({
    type: wsTypes.WEB_SOCKET_CHAT_SET_SOCKET_ID,
    payload: {socketId: webSocket.id},
});

export const websocketConnectionReceived = ({webSocket}) => ({
    type: wsTypes.WEB_SOCKET_CHAT_CONNECTION_RECEIVED,
    payload: {webSocket},
});

// export const getMoreChannelMessages = (channelData) => ({
//     type: wsTypes.WEB_SOCKET_CHAT_GET_MORE_CHANNEL_MESSAGES,
//     payload: channelData,
// });

// export const moreMessagesForChannelReceived = (driverID, messages) => (dispatch, getState) => {
//     const currentUser = getCurrentUser(getState());
//     dispatch({
//         type: wsTypes.WEB_SOCKET_CHAT_MORE_CHANNEL_MESSAGES_RECEIVED,
//         payload: {driverID, messages, currentUser},
//     });
// };

export const markAsReadByCurrentDispatcher = (driverID) => ({
    type: wsTypes.WEB_SOCKET_CHAT_MARK_AS_READ_BY_CURRENT_DISPATCHER,
    payload: {driverID},
});

export const markAsReadByEveryDispatcher = (driverID) => ({
    type: wsTypes.WEB_SOCKET_CHAT_MARK_AS_READ_BY_EVERY_DISPATCHER,
    payload: {driverID},
});

export const markLocalMessagesAsRead = (driverID) => ({
    type: wsTypes.WEB_SOCKET_CHAT_MARK_LOCAL_MESSAGES_AS_READ,
    payload: {driverID},
});

// export const joinServerActionCreator = (meta) => ({
//     type: wsTypes.WEB_SOCKET_CHAT_JOIN_SERVER_BY_DISPATCHER,
//     payload: meta,
// });

type JoinServerPayload = {
    meta: {token: string | null; serverPrefix: string};
};

const wsActionCreators = {
    joinServerActionCreator: (payload: JoinServerPayload) =>
        ({type: wsTypes.WEB_SOCKET_CHAT_JOIN_SERVER_BY_DISPATCHER, payload} as const),
    receiveDispatcherMessage: (payload: {message: WebsocketMessageResponse}) =>
        ({type: wsTypes.WS_CHAT_DISPATCHER_MESSAGE_RECEIVED, payload} as const),
    receiveDriverMessage: (payload: {message: WebsocketMessageResponse}) =>
        ({type: wsTypes.WS_CHAT_DRIVER_MESSAGE_RECEIVED, payload} as const),
};

export const joinServer = () => (dispatch, getState) => {
    const state = getState();

    const token = getUserAccessToken(state);

    dispatch(wsActionCreators.joinServerActionCreator({meta: {token, serverPrefix: appConfig.REACT_APP_PREFIX}}));
};

export const chatWSConnect = () => async () => {
    try {
        const webSocket = await wsRequests.chatWSConnectRequest();

        return webSocket;
    } catch (error) {
        return null;
    }
};

export const receiveChatDispatcherMessage = (message: WebsocketMessageResponse) => (dispatch) => {
    dispatch(wsActionCreators.receiveDispatcherMessage({message}));
};

export const receiveChatDriverMessage = (message: WebsocketMessageResponse) => (dispatch) => {
    dispatch(wsActionCreators.receiveDriverMessage({message}));
};
