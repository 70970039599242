export const normalizeAttachment = (attachment) => {
    return {
        extension: attachment.originalname.split('.').pop(),
        url: `api/chat/v2/files/${attachment.url}`,
        name: attachment.originalname,
        type: attachment.mimetype,
        size: attachment.size,
        id: attachment.id,
        extraData: null,
        isBlob: false,
    };
};
