// import {Dispatcher} from 'core/entities/Dispatcher/types';
// import User from 'core/entities/Dispatcher/types/User';
// import Driver from 'core/entities/Driver/types';
// import Truck from 'core/entities/Truck/types';

// import * as wsTypes from 'widgets/Chat/redux/actionTypes/wsTypes';
// import {MessageStatus, RawDriverChannelFromApi, RawMessageFromApi} from 'widgets/Chat/types';

// import File from 'types/File';

// NEW ↓
export const OPEN_DRIVERS_GROUP_WITH_MESSAGE = 'CHAT/DATA_FOR_OPEN_DRIVERS_GROUP_WITH_MESSAGE_RECEIVED' as const;
export const UNREAD_DRIVERS_GROUPS_INFO_RECEIVED = 'CHAT/UNREAD_DRIVERS_GROUPS_INFO_RECEIVED' as const;
export const DRIVERS_GROUP_ALL_MESSAGES_RECEIVED = 'CHAT/DRIVERS_GROUP_ALL_MESSAGES_RECEIVED' as const;
export const TRUCK_FOR_DRIVERS_GROUP_RECEIVED = 'CHAT/TRUCK_FOR_DRIVERS_GROUP_RECEIVED' as const;
export const ATTACHMENTS_TO_MESSAGE_RECEIVED = 'CHAT/ATTACHMENTS_TO_MESSAGE_RECEIVED' as const;
export const MORE_MESSAGES_RECEIVED = 'CHAT/DRIVERS_GROUPS_MORE_MESSAGES_RECEIVED' as const;
export const INIT_MESSAGES_RECEIVED = 'CHAT/DRIVERS_GROUPS_INIT_MESSAGES_RECEIVED' as const;
export const OPEN_DRIVERS_GROUP = 'CHAT/DATA_FOR_OPEN_DRIVERS_GROUP_RECEIVED' as const;
export const SET_ATTACHMENTS_UPLOADING = 'CHAT/SET_ATTACHMENTS_UPLOADING' as const;
export const DISPATCHER_DATA_RECEIVED = 'CHAT/DISPATCHER_DATA_RECEIVED' as const;
export const SEARCHED_DRIVERS_CLEARED = 'CHAT/SEARCHED_DRIVERS_CLEARED' as const;
export const DRIVERS_GROUPS_RECEIVED = 'CHAT/DRIVERS_GROUPS_RECEIVED' as const;
export const SEARCH_PARAMS_RECEIVED = 'CHAT/SEARCH_PARAMS_RECEIVED' as const;
export const LEAVE_DRIVERS_GROUP = 'CHAT/LEAVE_DRIVERS_GROUP' as const;
export const SET_MESSAGE_STATUS = 'CHAT/SET_MESSAGE_STATUS' as const;
export const TOGGLE_CHAT_WIDGET = 'CHAT/TOGGLE_CHAT_WIDGET' as const;

// old
// export const FETCH_TRUCK_DATA_FOR_DRIVER_CHANNEL = 'CHAT_FETCH_TRUCK_DATA_FOR_DRIVER_CHANNEL' as const;
// export const SET_DRIVER_SEARCH_PARAMS = 'CHAT_SET_DRIVER_SEARCH_PARAMS' as const;
// export const ENTER_TO_DRIVER_CHANNEL = 'CHAT_ENTER_TO_DRIVER_CHANNEL' as const;
// export const LEAVE_DRIVER_CHANNEL = 'CHAT_LEAVE_DRIVER_CHANNEL' as const;
// export const DATA_RECEIVED = 'CHAT_DATA_RECEIVED' as const;

// type ReceivedChatData = {
//     type: typeof DATA_RECEIVED;
//     payload: {
//         drivers: Driver[];
//         driversAvatars: File[];
//         dispatchers: Dispatcher[];
//         channels: RawDriverChannelFromApi[];
//         currentUser: User;
//     };
// };

// type ReceivedMoreChannelMessages = {
//     type: typeof wsTypes.WEB_SOCKET_CHAT_MORE_CHANNEL_MESSAGES_RECEIVED;
//     payload: {
//         driverID: number;
//         messages: RawMessageFromApi[];
//         currentUser: User;
//     };
// };

// type EnterDriverChannel = {
//     type: typeof ENTER_TO_DRIVER_CHANNEL;
//     payload: {driverID: number};
// };

// type FetchTruckDataFroChannel = {
//     type: typeof FETCH_TRUCK_DATA_FOR_DRIVER_CHANNEL;
//     payload: {driverTruckData: Truck};
// };

// type LeaveDriverChannel = {
//     type: typeof LEAVE_DRIVER_CHANNEL;
// };

// type DriverSearch = {
//     type: typeof SET_DRIVER_SEARCH_PARAMS;
//     payload: {truckNumber: string};
// };

// type ReceivedChatMessage = {
//     type: typeof wsTypes.WS_CHAT_DISPATCHER_MESSAGE_RECEIVED;
//     payload: {message: RawDriverChannelFromApi; currentUser: User};
// };

// type MarkLocalMessagesAsRead = {
//     type: typeof wsTypes.WEB_SOCKET_CHAT_MARK_LOCAL_MESSAGES_AS_READ;
//     payload: {driverID: number};
// };

// type SetWebSocketID = {
//     type: typeof wsTypes.WEB_SOCKET_CHAT_SET_SOCKET_ID;
//     payload: {socketId: string | null};
// };

// type ClearSocketID = {
//     type: typeof wsTypes.WEB_SOCKET_CHAT_CLEAR_SOCKET_ID;
// };

// type SendingMessage = {
//     type: typeof SENDING_MESSAGE;
//     payload: {messageStatus: MessageStatus};
// };

// type SendMessageError = {
//     type: typeof wsTypes.WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE_ERROR;
// };

// type OpenDriverChannel = {
//     type: typeof wsTypes.WEB_SOCKET_CHAT_MARK_AS_READ_BY_CURRENT_DISPATCHER;
// };

// type WebSocketDisconnect = {
//     type: typeof wsTypes.WEB_SOCKET_CHAT_DISCONNECT;
// };
