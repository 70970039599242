// import {slice, without} from 'lodash';
// import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
// import sortBy from 'lodash/sortBy';
// import {change} from 'redux-form';
import {v4 as uuidv4} from 'uuid';

// import {SEND_MESSAGE_FORM} from 'widgets/Chat/constants';
// import {NormalizedFile} from 'components/ui/Files/FileUpload/types';
import {getUploadedFileExtension, getUploadedFileUrl} from 'components/ui/Files/FileUpload/utils';

// import getText from 'utils/getText';

// import StoredFile from 'types/File';

// export const omitRemoved = (files: StoredFile[], removedFileIds: number[]): StoredFile[] => {
//     return files.filter((file: StoredFile): boolean => !removedFileIds.includes(file.id));
// };

// export const composeBadge = (primaryIndex: number, secondaryIndex: number): string => {
//     const rateConfirmation = getText('rateConfirmation').toLowerCase();

//     return `load ${rateConfirmation} #${primaryIndex}.${secondaryIndex}`;
// };

// export const groupByPrimaryIndex = (files: NormalizedFile[]): NormalizedFile[][] => {
//     const groupedInObject = groupBy(files, 'extraData.primary_index');

//     return Object.values(groupedInObject);
// };

// export const sortFiles = (files: NormalizedFile[]): NormalizedFile[] => {
//     return sortBy(files, ['extraData.primary_index', 'extraData.secondary_index']);
// };

export const normalizeFileList = (fileList: FileList | null) => {
    return map(fileList, (file) => ({
        extension: getUploadedFileExtension(file),
        url: getUploadedFileUrl(file),
        name: file.name,
        type: file.type,
        size: file.size,
        isBlob: true,
        id: uuidv4(),
        file,
    }));
};
