import React from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import {WrappedFieldProps} from 'redux-form';

import getValidationState from 'components/ui/Form/getValidationState';
import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';

import Option from '../Option';

import styles from './styles.module.scss';

export type Option = {label: string; value: unknown; icon?: string};
type OwnProps = {
    input: WrappedFieldProps['input'];
    isFirstOptionSelected?: boolean;
    meta: WrappedFieldProps['meta'];
    closeMenuOnSelect?: boolean;
    errorPlacement?: string;
    options?: Option[];
    isClearable?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
};

const Select: React.FC<OwnProps> = (props) => {
    const {input, meta, isDisabled, errorPlacement} = props;

    const renderOption = (option): JSX.Element => <Option {...option} input={input} />;

    const validationState = getValidationState(meta);
    const wrapClassNames = classNames(styles.wrap, {
        [styles.error]: !validationState,
        [styles.disabled]: isDisabled,
    });

    return (
        <OverlayTriggerCustom className={wrapClassNames} inputMeta={meta} placement={errorPlacement}>
            <ReactSelect
                {...props}
                isMulti
                styles={styles}
                isClearable={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                components={{Option: renderOption}}
            />
        </OverlayTriggerCustom>
    );
};

export default Select;
