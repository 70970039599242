import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {WrappedFieldProps} from 'redux-form';

import useChatActions from 'widgets/Chat/hooks/useChatActions';
import {MessageFormValues} from 'widgets/Chat/types/form';

import ButtonFileInput from 'components/ui/Files/FileUpload/components/ButtonFileInput';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import AttachPreview from './components/AttachPreview';

import {normalizeFileList} from './utils';

import styles from './styles.module.scss';

type OwnProps = {
    disabled: boolean;
};

const getName = getTypeFieldNameFactory<MessageFormValues>();

const AttachmentsUpload: React.FC<OwnProps & WrappedFieldProps> = (props) => {
    const {
        input: {name: inputName},
        meta: {form},
        disabled,
    } = props;

    const attachments = useGetReduxFormValue(form, getName('attachments'));
    const changeFormValue = useChangeReduxFormValue(form);
    const {sendAttachmentsByUpload} = useChatActions();

    // const existingFiles = [];

    // const normalizedStoredFiles = useMemo((): NormalizedFile[] => {
    //     const filteredFiles = omitRemoved(existingFiles, fileIDsToBeRemoved);

    //     return filteredFiles.map(
    //         (file: StoredFile): NormalizedFile =>
    //             normalizeFile(file, {remove: () => pushFileToDeleteList(file)}) as NormalizedFile,
    //     );
    // }, [existingFiles, fileIDsToBeRemoved]);

    // const normalizedNewFiles = useMemo((): NormalizedFile[] => {
    //     return newFiles.map(
    //         (file: {file: File}, index: number): NormalizedFile =>
    //             normalizeFile(file, {remove: () => arrayRemove(inputName, index)}) as NormalizedFile,
    //     );
    // }, [newFiles]);

    // const mergedFiles = useMemo((): NormalizedFile[] => {
    //     return sortFiles(normalizedStoredFiles.concat(normalizedNewFiles));
    // }, [normalizedStoredFiles, normalizedNewFiles]);

    // const groupedNormalizedFiles = useMemo((): NormalizedFile[][] => {
    //     return groupByPrimaryIndex(mergedFiles);
    // }, [mergedFiles]);

    // const handleChange = useCallback((event: React.FormEvent<HTMLInputElement>, extraData) => {
    //     const {files} = event.currentTarget;

    //     if (!files || files.length === 0) {
    //         return;
    //     }

    //     const newFile = {file: head(files), ...extraData};
    //     // temp fix for disable possibility upload many files
    //     change(inputName, [newFile]);
    // }, []);

    const handleUpload = (event: React.FormEvent<HTMLInputElement>) => {
        sendAttachmentsByUpload({fileList: event.currentTarget.files});

        changeFormValue(inputName, normalizeFileList(event.currentTarget.files));

        // const lastFile = last(mergedFiles);
        // const newPrimaryIndex = lastFile ? lastFile.extraData.primary_index + 1 : 1;

        // const extraData = {
        //     badge: composeBadge(newPrimaryIndex, 1),
        //     primary_index: newPrimaryIndex,
        //     secondary_index: 1,
        // };

        // const normalizedFiles = map(event.currentTarget.files, (file) => normalizeFile(file));

        // handleChange(event, extraData);
    };

    // const renderPreviews = (): ReactNodeArray => {
    //     return groupedNormalizedFiles.map((fileGroup) =>
    //         fileGroup.map((file: NormalizedFile, fileIndex) => {
    //             return <AttachPreview key={fileIndex} file={file} handleRemove={file.handleRemove} />;
    //         }),
    //     );
    // };

    // const previews = useMemo(renderPreviews, [groupedNormalizedFiles]);
    const renderAttachmentsPreview = (attachments || []).map((attachment) => (
        <AttachPreview key={attachment.id} file={attachment} />
    ));

    return (
        <div className={styles.upload}>
            {!isEmpty(renderAttachmentsPreview) ? (
                <>
                    <div className="attachments-list">{renderAttachmentsPreview}</div>
                    <div className="count-preview">1 of 1 file selected</div>
                </>
            ) : null}

            <ButtonFileInput
                className="button-attach"
                onChange={handleUpload}
                buttonIcon="paperclip"
                inputName={inputName}
                disabled={disabled}
                colorTheme="white"
                multiple={false}
            />
        </div>
    );
};

// export default connect(mapExistingFilesFromFormState)(withFormActionCreators(AttachmentsUpload));
export default AttachmentsUpload;
