export const DRIVER_SEARCH_FORM = 'chat-driver-search-form';
export const SEND_MESSAGE_FORM = 'chat-send-message-form';
export const SENDER_TYPE = 'dispatcher';

export const CHAT_EVENTS = {
    markAsReadByCurrentDispatcher: 'markAsReadByCurrentDispatcher',
    markAsReadByEveryDispatcher: 'markAsReadByEveryDispatcher',

    markAsRead: 'markAsReadByDispatchers',
    getMoreMessages: 'getMoreMessages',
    newMessage: 'newMessage',
};

export const WS_EVENTS = {
    NEW_MESSAGE_BY_DISPATCHER: 'NEW_MESSAGE_BY_DISPATCHER',
    JOIN_SERVER_BY_DISPATCHER: 'JOIN_SERVER_BY_DISPATCHER',
    NEW_MESSAGE_BY_DRIVER: 'NEW_MESSAGE_BY_DRIVER',
};
