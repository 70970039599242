import React from 'react';
import {Field} from 'redux-form';

import Option from './components/Option';
import Select from './components/Select';

type Option = {label: string; value: any};
type OptionGroup = {label: string; options: Option[]};
type OwnProps = {
    options: Option[] | OptionGroup[];
    isClearable?: boolean;
    placeholder?: string;
    className?: string;
    name: string;
};

const DropDownCheckbox: React.FC<OwnProps> = (props) => {
    const {isClearable = false} = props;

    return <Field {...props} component={Select} isClearable={isClearable} />;
};

export default DropDownCheckbox;
