import React from 'react';

import useChatState from 'widgets/Chat/hooks/useChatState';
import {DriversGroup as DriversGroupType} from 'widgets/Chat/types/group';

import styles from './styles.module.scss';

type OwnProps = {
    driversGroup: DriversGroupType;
};

const UnreadInfo: React.FC<OwnProps> = (props) => {
    const {driversGroup} = props;

    const {unreadInfo} = useChatState();

    if (!unreadInfo) {
        return null;
    }

    const currentUnreadInfo = driversGroup.id ? unreadInfo[driversGroup.id] : null;

    if (!currentUnreadInfo) {
        return null;
    }

    return <div className={styles.count}>{(currentUnreadInfo?.unreadMsgIDs || []).length}</div>;
};

export default UnreadInfo;
