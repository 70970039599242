import React from 'react';

import useChatState from 'widgets/Chat/hooks/useChatState';

import {TravelOrderLink} from 'components/ui/Links';

const CurrentTravelOrder: React.FC = () => {
    const {openedTruck} = useChatState();

    const currentTravelOrderNumber = openedTruck?.current_travel_order_number;

    if (!openedTruck || !currentTravelOrderNumber) {
        return null;
    }

    return (
        <>
            ;{' '}
            <TravelOrderLink
                travelOrder={{number: currentTravelOrderNumber}}
                content={`T.O. #${currentTravelOrderNumber}`}
            />
        </>
    );
};

export default CurrentTravelOrder;
