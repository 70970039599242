import React from 'react';

import countries from 'utils/data/countries';

import GeneralBadge from '../GeneralBadge';

type OwnProps = {
    tooltipPosition?: 'left' | 'right' | 'bottom';
    withBorderRadius?: boolean;
    isTooltip?: boolean;
    language?: string;
};

const LanguageBadge: React.FC<OwnProps> = (props) => {
    const {language, tooltipPosition = 'left', withBorderRadius = false, isTooltip} = props;

    if (!language) {
        return null;
    }

    const currentLanguage = language.toUpperCase();
    const languagePriority = `language priority: ${countries[currentLanguage]?.name}`;

    return (
        <GeneralBadge
            withBorderRadius={withBorderRadius}
            tooltipPosition={tooltipPosition}
            tooltip={languagePriority}
            text={currentLanguage}
            isTooltip={isTooltip}
        />
    );
};

export default LanguageBadge;
