import chatApi from 'services/chatApi/http';

export const fetchGroups = () => {
    return chatApi.get('groups/drivers');
};

export const fetchUnreadMessages = ({tmsUserID}) => {
    return chatApi.get(`groups/drivers/messages/unread/dispatcher/${tmsUserID}`);
};

export const fetchLatestMessagesByGroupAndMessageIDs = ({groupID, messageID, limit = '10'}) => {
    return chatApi.get(`groups/drivers/${groupID}/messages/latest/${messageID}?limit=${limit}`);
};

export const fetchLatestMessagesByGroupID = ({groupID, limit = '10'}) => {
    return chatApi.get(`groups/drivers/${groupID}/messages/latest?limit=${limit}`);
};

export const fetchAllGroupMessages = ({groupID}) => {
    return chatApi.get(`groups/drivers/${groupID}/messages/all`);
};

export const fetchChatDispatcherData = ({requestBody}) => {
    return chatApi.post('users/dispatcher/me', requestBody);
};

export const sendAttachmentsToGroup = ({requestBody}) => {
    return chatApi.post('attachments', requestBody);
};
