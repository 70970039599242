import React, {useEffect} from 'react';
import classNames from 'classnames';

import ChatAlert from 'widgets/Chat/components/ChatAlert';
import DriverAvatar from 'widgets/Chat/components/DriverAvatar';
import MessageForm from 'widgets/Chat/components/MessageForm';
import useChatActions from 'widgets/Chat/hooks/useChatActions';
import useChatState from 'widgets/Chat/hooks/useChatState';

import LanguageBadge from 'components/ui/Badges/LanguageBadge';
import Button from 'components/ui/Buttons/Button';
import {DriverLink, TruckLink} from 'components/ui/Links';
import Tooltip from 'components/ui/Tooltip';

import {phoneNumberWithBraces} from 'utils';

import CurrentTravelOrder from './components/CurrentTravelOrder';
import ListMessages from './components/ListMessages';

import styles from './styles.module.scss';

const DriversGroupExpanded: React.FC = () => {
    const {openedDriversGroup, driversGroups, openedTruck} = useChatState();
    const actions = useChatActions();

    const {tmsUserID} = openedDriversGroup || {};

    useEffect(() => {
        if (tmsUserID) {
            actions.getTruckForDriversGroup({tmsUserID});
            actions.getDriversGroupInitLatestMessages({tmsUserID});
        }
    }, [tmsUserID]);

    if (!tmsUserID) {
        return null;
    }

    const driversGroup = driversGroups.byTmsUserID[openedDriversGroup.tmsUserID];

    const [driver] = driversGroup.drivers || [];

    const driverTitle = `${driver.isOwner ? '(o/d)' : '(1dr)'} ${driver.name}`;

    const handleGetDriversGroupAllMessages = () => {
        actions.getDriversGroupAllMessages({tmsUserID});
    };

    const handleLeaveDriversGroup = () => {
        actions.leaveDriversGroup({tmsUserID});
    };

    return (
        <div className={styles.room}>
            <div className={styles.back} onClick={handleLeaveDriversGroup}>
                <i className="fa fa-long-arrow-left" aria-hidden="true" /> Back to the list
            </div>

            <ChatAlert />

            <div className={styles.driver}>
                <div className="w-100 d-flex align-items-end justify-content-between">
                    <div className={styles.left}>
                        <DriverAvatar driver={driver} />

                        <div className="ml20" />

                        <div>
                            <div className={classNames(styles.title, 'ellips-text')}>
                                <DriverLink content={driverTitle} driver={driver} className="second-link" />
                            </div>

                            <div className={classNames(styles.phone, {[styles.main]: driver.isMain})}>
                                {phoneNumberWithBraces(driver.mobilePhone)}
                            </div>

                            <div className={styles.truck}>
                                {openedTruck ? (
                                    <TruckLink content={`Truck #${openedTruck.number}`} truck={openedTruck} />
                                ) : null}

                                <CurrentTravelOrder />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex">
                        <Tooltip tooltipContent="mark as read for all users" position="right" typeContent="button">
                            <Button buttonIcon="check" buttonSize="icon" onClick={() => {}} />
                        </Tooltip>

                        <Tooltip
                            tooltipContent="load all messages, use CTRL + F to search"
                            typeContent="button"
                            position="right"
                        >
                            <Button buttonIcon="refresh" buttonSize="icon" onClick={handleGetDriversGroupAllMessages} />
                        </Tooltip>
                    </div>
                </div>

                <div className={styles.badges}>
                    {driver.isMain ? <div className={styles.main}>main</div> : null}

                    <LanguageBadge language={driver.language} withBorderRadius={true} isTooltip={false} />
                </div>
            </div>

            <ListMessages />

            <MessageForm />
        </div>
    );
};

export default DriversGroupExpanded;
