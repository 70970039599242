import head from 'lodash/head';
import {reset} from 'redux-form';

import {checkIsUserLoggedIn, getUserAccessToken} from 'store/reducers/auth/selectors';
import {getCurrentDispatcher} from 'store/reducers/userData/selectors';

import {searchTrucks} from 'core/gateways/TruckApiGateway/requests';

import * as constants from 'widgets/Chat/constants';
import * as wsActions from 'widgets/Chat/redux/actions/wsActions';
import * as types from 'widgets/Chat/redux/actionTypes';
import * as mappers from 'widgets/Chat/redux/mappers';
import * as selectors from 'widgets/Chat/redux/selectors';
import * as chatRequests from 'widgets/Chat/requests';
import {DriverSearchFormValues, MessageFormValues} from 'widgets/Chat/types/form';
import {DriversGroup} from 'widgets/Chat/types/group';

export const chatActionCreators = {
    driversGroupsReceived: (payload: {driversGroups: DriversGroup[]}) =>
        ({type: types.DRIVERS_GROUPS_RECEIVED, payload} as const),
    toggleChat: () => ({type: types.TOGGLE_CHAT_WIDGET} as const),
    clearSearchedDrivers: () => ({type: types.SEARCHED_DRIVERS_CLEARED} as const),
    leaveDriversGroup: (payload) => ({type: types.LEAVE_DRIVERS_GROUP, payload} as const),
    openDriversGroup: (payload: {tmsUserID: number}) => ({type: types.OPEN_DRIVERS_GROUP, payload} as const),
    setTruckForDriversGroup: (payload) => ({type: types.TRUCK_FOR_DRIVERS_GROUP_RECEIVED, payload} as const),
    openDriversGroupWithMessage: (payload) => ({type: types.OPEN_DRIVERS_GROUP_WITH_MESSAGE, payload} as const),
    setDriversGroupInitMessages: (payload) => ({type: types.INIT_MESSAGES_RECEIVED, payload} as const),
    insertDriversGroupMoreMessages: (payload) => ({type: types.MORE_MESSAGES_RECEIVED, payload} as const),
    setUnreadDriversGroupsInfo: (payload) => ({type: types.UNREAD_DRIVERS_GROUPS_INFO_RECEIVED, payload} as const),
    setChatDispatcherData: (payload) => ({type: types.DISPATCHER_DATA_RECEIVED, payload} as const),
    setAttachmentsUploading: (payload: boolean) => ({type: types.SET_ATTACHMENTS_UPLOADING, payload} as const),
    setAttachmentsToMessage: (payload: {attachments}) =>
        ({type: types.ATTACHMENTS_TO_MESSAGE_RECEIVED, payload} as const),
    insertDriversGroupAllMessages: (payload: {messages}) =>
        ({type: types.DRIVERS_GROUP_ALL_MESSAGES_RECEIVED, payload} as const),
    searchDriverByTruckNumber: (payload: {formValues: Partial<DriverSearchFormValues>}) =>
        ({type: types.SEARCH_PARAMS_RECEIVED, payload} as const),
};

// const getFilesFromAttachments = (attachments) => {
//     if (!attachments || attachments.length === 0) {
//         return undefined;
//     }

//     return attachments
//         .map((attachment) => {
//             return attachment && attachment.file
//                 ? {buffer: attachment.file, name: attachment.file.name, type: attachment.file.type}
//                 : undefined;
//         })
//         .filter(Boolean);
// };

// const getMoreChannelMessagesAction = (
//     channel,
//     {getAllMessages}: {getAllMessages: boolean} = {getAllMessages: false},
// ) => {
//     const lastMessage: any = head(channel.messages);

//     if (!lastMessage) {
//         return;
//     }

//     const MESSAGES_LIMIT = 10;

//     const data = {
//         driverID: channel.driver.id,
//         channelID: channel.channelID,
//         lastMessageID: lastMessage.id,
//         limit: getAllMessages ? undefined : MESSAGES_LIMIT,
//     };

//     return wsActions.getMoreChannelMessages(data);
// };

// export const fetchChannelMoreMessages = (channel) => (dispatch) => {
//     const moreMessagesAction = getMoreChannelMessagesAction(channel);

//     if (moreMessagesAction) {
//         dispatch(moreMessagesAction);
//     }
// };

// export const fetchChannelAllMessages = (channel) => (dispatch) => {
//     const moreMessagesAction = getMoreChannelMessagesAction(channel, {getAllMessages: true});

//     if (moreMessagesAction) {
//         dispatch(moreMessagesAction);
//     }
// };

// export const enterToDriverChannel = (selectedDriverID) => (dispatch, getState) => {
//     const allChannels = selectors.getAllChannels(getState());
//     const selectedChannel = selectors.getChannelByDriverID(allChannels, selectedDriverID);

//     dispatch({type: types.ENTER_TO_DRIVER_CHANNEL, payload: {driverID: selectedDriverID}});

//     const moreMessagesAction = getMoreChannelMessagesAction(selectedChannel);

//     if (moreMessagesAction) {
//         dispatch(moreMessagesAction);
//     }

//     dispatch(wsActions.markAsReadByCurrentDispatcher(selectedDriverID));
// };

// export const fetchDataForDriverChannel = (driverID) => (dispatch) => {
//     searchTrucks({driver: driverID})
//         .then((response) => {
//             dispatch({
//                 type: types.FETCH_TRUCK_DATA_FOR_DRIVER_CHANNEL,
//                 payload: {driverTruckData: head(response.data)},
//             });
//         })
//         .catch((e) => {
//             console.warn('Error on search driver truck data: ', e);
//         });
// };

// export const leaveDriverChannel = () => (dispatch, getState) => {
//     const selectedChannelDriverID = selectors.getSelectedChannelDriverID(getState());

//     dispatch(reset(constants.DRIVER_SEARCH_FORM));
//     dispatch(wsActions.markLocalMessagesAsRead(selectedChannelDriverID));
//     dispatch({type: types.LEAVE_DRIVER_CHANNEL});
// };

// export const sendMessageToChannel = (message) => (dispatch, getState) => {
//     const state = getState();

//     const isMessageSendingStatus = selectors.checkIsMessageStatusSending(state);
//     const currentDispatcher = getCurrentDispatcher(state);
//     const channel = selectors.getSelectedChannel(state);

//     const attachments = getFilesFromAttachments(message.attachments);
//     const senderName = currentDispatcher.fake_full_name;
//     const senderExtension = currentDispatcher.extension;
//     const senderID = currentDispatcher.id;
//     const driverID = channel.driver.id;

//     if (!message.text && !attachments) {
//         return;
//     }

//     if (isMessageSendingStatus) {
//         return;
//     }

//     const newMessage = {
//         senderType: constants.SENDER_TYPE,
//         text: message.text,
//         senderExtension,
//         attachments,
//         senderName,
//         senderID,
//         driverID,
//     };

//     dispatch(wsActions.sendChatMessage(newMessage));
//     dispatch({type: types.SENDING_MESSAGE, payload: {messageStatus: 'sending'}});
// };

export const getDriversGroups = () => async (dispatch, getState) => {
    const state = getState();

    const driversGroups = selectors.getDriversGroups(state);

    if (driversGroups.allTmsUserIDs) {
        return;
    }

    try {
        const {data} = await chatRequests.fetchGroups();

        dispatch(chatActionCreators.driversGroupsReceived({driversGroups: data}));
    } catch (e) {
        console.log(e);
    }
};

export const getChatDispatcherData = () => async (dispatch, getState) => {
    const state = getState();

    const currentDispatcher = getCurrentDispatcher(state);

    const requestBody = {
        name: currentDispatcher.fake_full_name,
        extension: currentDispatcher.extension,
        tmsUserID: currentDispatcher.user.id,
        tmsEntityID: currentDispatcher.id,
    };

    try {
        const {data} = await chatRequests.fetchChatDispatcherData({requestBody});

        dispatch(chatActionCreators.setChatDispatcherData(data));
    } catch (e) {
        console.log(e);
    }
};

export const init = () => async (dispatch, getState) => {
    const state = getState();

    const isUserLoggedIn = checkIsUserLoggedIn(state);

    if (!isUserLoggedIn) {
        return;
    }

    dispatch(getChatDispatcherData());

    const webSocket = await dispatch(wsActions.chatWSConnect());

    if (!webSocket) {
        return;
    }

    dispatch(wsActions.websocketConnectionReceived({webSocket}));
};

export const openDriversGroup = (params: {tmsUserID: number}) => (dispatch, getState) => {
    dispatch(chatActionCreators.openDriversGroup(params));
};

export const sendAttachmentsByUpload = (params: {fileList: FileList | null}) => async (dispatch) => {
    const {fileList} = params;

    dispatch(chatActionCreators.setAttachmentsUploading(true));

    const requestBody = mappers.transformFileListToRequestBody(fileList);

    try {
        const {data} = await chatRequests.sendAttachmentsToGroup({requestBody});

        dispatch(chatActionCreators.setAttachmentsToMessage({attachments: data}));
    } catch (e) {
        dispatch(reset(constants.SEND_MESSAGE_FORM));
    } finally {
        dispatch(chatActionCreators.setAttachmentsUploading(false));
    }
};

export const sendMessageByDispatcherToDriversGroup = (params: {message: Partial<MessageFormValues>}) => (
    dispatch,
    getState,
) => {
    const {message} = params;

    const state = getState();

    const isMessageSendingStatus = selectors.checkIsMessageStatusSending(state);
    const chatDispatcher = selectors.getChatDispatcher(state);
    const token = getUserAccessToken(state);

    if (!token || !chatDispatcher.id) {
        return;
    }

    if (isMessageSendingStatus) {
        return;
    }

    const requestBody = mappers.transformDispatcherMessageToRequestBody(state, message);

    dispatch(wsActions.sendChatMessage(requestBody));
    dispatch({type: types.SET_MESSAGE_STATUS, payload: {messageStatus: 'sending'}});
};

export const getTruckForDriversGroup = (params: {tmsUserID: number}) => async (dispatch, getState) => {
    const {tmsUserID} = params;

    const state = getState();

    const driversGroups = selectors.getDriversGroups(state);
    const driversGroup = driversGroups.byTmsUserID[tmsUserID];

    const [driver] = driversGroup.drivers || [];

    try {
        const {data} = await searchTrucks({driver: driver?.tmsEntityID});

        dispatch(chatActionCreators.setTruckForDriversGroup(head(data)));
    } catch (e) {
        console.log(e);
    }
};

export const getDriversGroupInitLatestMessages = (params: {tmsUserID: number}) => async (dispatch, getState) => {
    const {tmsUserID} = params;

    const state = getState();

    const driversGroups = selectors.getDriversGroups(state);
    const driversGroup = driversGroups.byTmsUserID[tmsUserID];

    if (!driversGroup.id) {
        return;
    }

    try {
        const {data} = await chatRequests.fetchLatestMessagesByGroupID({groupID: driversGroup.id});

        dispatch(chatActionCreators.setDriversGroupInitMessages({messages: data.reverse(), tmsUserID}));
    } catch (e) {
        console.log(e);
    }
};

export const leaveDriversGroup = (params: {tmsUserID: number}) => (dispatch, getState) => {
    const {tmsUserID} = params;

    const selectedChannelDriverID = selectors.getSelectedChannelDriverID(getState());

    dispatch(chatActionCreators.leaveDriversGroup({tmsUserID}));
    dispatch(reset(constants.DRIVER_SEARCH_FORM));
    // dispatch(wsActions.markLocalMessagesAsRead(selectedChannelDriverID));
};

export const getDriversGroupMoreMessages = (params: {tmsUserID: number}) => async (dispatch, getState) => {
    const {tmsUserID} = params;

    const state = getState();

    const driversGroups = selectors.getDriversGroups(state);
    const driversGroup = driversGroups.byTmsUserID[tmsUserID];

    const oldestMessage: any = head(driversGroup.messages);

    try {
        const {data} = await chatRequests.fetchLatestMessagesByGroupAndMessageIDs({
            messageID: oldestMessage.id,
            groupID: driversGroup.id,
        });

        dispatch(chatActionCreators.insertDriversGroupMoreMessages({messages: data.reverse(), tmsUserID}));
    } catch (e) {
        console.log(e);
    }
};

export const getDriversGroupsCommonUnreadInfo = () => async (dispatch, getState) => {
    const state = getState();

    const currentDispatcher = getCurrentDispatcher(state);

    try {
        const {data} = await chatRequests.fetchUnreadMessages({tmsUserID: currentDispatcher.user.id});

        dispatch(chatActionCreators.setUnreadDriversGroupsInfo({data}));
    } catch (e) {
        console.log(e);
    }
};

export const openChatGroupWithMessageToFill = (params: {driverID: number; messageText?: string}) => async (
    dispatch,
) => {
    const {driverID, messageText} = params;

    await dispatch(getDriversGroups());

    dispatch(chatActionCreators.openDriversGroupWithMessage({driverID, messageText}));
};

export const searchDriverByTruckNumber = (params: {formValues: Partial<DriverSearchFormValues>}) => (dispatch) => {
    dispatch(chatActionCreators.searchDriverByTruckNumber(params));
};

export const clearSearchedDrivers = () => (dispatch) => {
    dispatch(chatActionCreators.clearSearchedDrivers());
};

export const getDriversGroupAllMessages = (params: {tmsUserID: number}) => async (dispatch, getState) => {
    const {tmsUserID} = params;

    const state = getState();

    const driversGroups = selectors.getDriversGroups(state);
    const driversGroup = driversGroups.byTmsUserID[tmsUserID];

    if (!driversGroup.id) {
        return;
    }

    try {
        const {data} = await chatRequests.fetchAllGroupMessages({groupID: driversGroup.id});

        dispatch(chatActionCreators.insertDriversGroupAllMessages({messages: data}));
    } catch (e) {
        console.log(e);
    }
};

export const getUnreadMessagesAmountByDriverID = (params: {driverID: number}) => (dispatch, getState) => {
    const {driverID} = params;

    const state = getState();

    const driver = mappers.getDriverFromDriversGroupsByDriverID({state: state.chat, driverID});

    if (!driver) {
        return;
    }

    const driversGroups = selectors.getDriversGroups(state);
    const driversGroup = driversGroups.byTmsUserID[driver.tmsUserID];

    return driversGroup.unreadMessages.amount;
};

export const openDriversGroupByDriverID = (params: {driverID: number}) => (dispatch, getState) => {
    const {driverID} = params;

    const state = getState();

    const driver = mappers.getDriverFromDriversGroupsByDriverID({state: state.chat, driverID});

    if (!driver) {
        return;
    }

    dispatch(openDriversGroup({tmsUserID: driver.tmsUserID}));
};

export const checkIsAvailableDriversGroupByDriverID = (params: {driverID: number}) => (dispatch, getState) => {
    const {driverID} = params;

    const state = getState();

    const driver = mappers.getDriverFromDriversGroupsByDriverID({state: state.chat, driverID});

    return Boolean(driver);
};
