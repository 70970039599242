import head from 'lodash/head';

import {checkIsDriverLoggedIn} from 'core/entities/Driver/modules/driverLogin';
import Driver from 'core/entities/Driver/types';
import Invoice from 'core/entities/Invoice/types';
import {getTruckDrivers} from 'core/entities/Truck/modules/truckDrivers';
import {fetchTravelOrder} from 'core/gateways/TravelOrderApiGateway/requests/indexNew';

import {getPickUpDeliveryInfo} from 'deprecated/core/entities/Load';

import {SearchFormParams} from '../types';

export const transformSearchParamsForRequest = (searchParams: SearchFormParams) => {
    if (searchParams?.loadType) {
        return {
            ...searchParams,
            loadType: searchParams.loadType,
        };
    }

    return searchParams;
};

const getFirstDriverFromInvoice = async (invoice: Invoice): Promise<Driver | undefined> => {
    // we should get TO from load instead of check invoice status and get it from snapshot cause in snapshot might be empty data for truck drivers
    // see invoice.snapshot.load.travelOrder.truck
    const travelOrderData = head(invoice?.load?.travel_order);

    if (!travelOrderData) {
        return;
    }

    const {data: travelOrder} = await fetchTravelOrder(travelOrderData.id);

    if (!travelOrder) {
        return;
    }

    const {firstDriver} = getTruckDrivers(travelOrder.truck) || {};

    return firstDriver;
};

export const getMessageDataForDriverWithoutPOD = async (invoice: Invoice) => {
    const {load} = invoice;

    const {firstPickUp, lastDelivery} = getPickUpDeliveryInfo(load);

    const firstDriver = await getFirstDriverFromInvoice(invoice);

    if (!firstDriver) {
        return;
    }

    const isDriverLoggedIn = checkIsDriverLoggedIn(firstDriver);

    if (firstDriver?.is_deleted || !isDriverLoggedIn) {
        return;
    }

    const firstPickupCity = firstPickUp?.shippers?.city;
    const firstPickupState = firstPickUp?.shippers?.state;
    const lastDeliveryCity = lastDelivery?.shippers?.city;
    const lastDeliveryState = lastDelivery?.shippers?.state;
    // Hi <First Name>, please provide POD on load "1st PU City, ST" - "Last DEL City, ST
    const messageText = `Hi ${firstDriver.full_name}, please provide POD on load ${firstPickupCity} ${firstPickupState} - ${lastDeliveryCity} ${lastDeliveryState}`;

    return {driverID: firstDriver.id, messageText};
};
