import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import * as wsRequests from 'services/chatApi/websocket';

import useChatState from 'widgets/Chat/hooks/useChatState';
import {websocketConnectionReceived} from 'widgets/Chat/redux/actions/wsActions';

const DELAY = 2000;

const useChatAlert = () => {
    const [state, setState] = useState({connecting: false, failure: false, success: false});
    const {chatClientSocketId} = useChatState();
    const dispatch = useDispatch();

    useEffect(() => {
        if (state.connecting) {
            setState((prev) => ({...prev, failure: false, success: false}));
        }
    }, [state.connecting]);

    useEffect(() => {
        if (state.success || state.failure) {
            setTimeout(() => {
                setState((prev) => ({...prev, failure: false, success: false}));
            }, DELAY);
        }
    }, [state.success, state.failure]);

    const connectionRequest = async () => {
        if (state.connecting) {
            return;
        }

        try {
            setState((prev) => ({...prev, connecting: true}));

            const webSocket = await wsRequests.chatWSConnectRequest();

            dispatch(websocketConnectionReceived({webSocket}));

            setState((prev) => ({...prev, success: true}));
        } catch (e) {
            setState((prev) => ({...prev, failure: true}));
        } finally {
            setState((prev) => ({...prev, connecting: false}));
        }
    };

    return {
        connecting: state.connecting,
        failure: state.failure,
        success: state.success,

        chatClientSocketId,
        connectionRequest,
    };
};

export default useChatAlert;
