import React from 'react';
import DOMPurify from 'dompurify';
import Linkify from 'linkify-react';
import isEmpty from 'lodash/isEmpty';

import useChatState from 'widgets/Chat/hooks/useChatState';
import {WebsocketMessageResponse} from 'widgets/Chat/types/message';

import DispatchersExtension from 'components/common/Dispatcher/DispatchersExtension';
import FilePreview from 'components/ui/Files/FileUpload/components/FilePreview';

import {createDate} from 'utils/dateTime';

import MessageItemNotification from './components/MessageItemNotification';

import {normalizeAttachment} from './utils';

import styles from './styles.module.scss';

type OwnProps = {
    message: WebsocketMessageResponse;
};

const MessageItem: React.FC<OwnProps> = (props) => {
    const {message} = props;

    const {unreadInfo} = useChatState();

    const {id, createdAt, user, text, isNotification, groupID} = message || {};

    const isDispatcher = user.type === 'dispatcher';
    const isDriver = user.type === 'driver';

    const messageCreationDate = createDate(createdAt, {fromISO: true}).fullDate;

    // const isUnread = (unreadInfo[groupID]?.unreadMsgIDs || []).some((itm) => itm === id);

    const renderAttachments = message.attachments.map((attachment) => (
        <FilePreview key={attachment.id} file={normalizeAttachment(attachment)} grid={true} linkInNewTab={true} />
    ));

    const messageBody = (
        <>
            {text && !isNotification ? (
                <div className="message-text">
                    <Linkify options={{target: '_blank', className: 'message-link'}}>
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(text)}} />
                    </Linkify>
                </div>
            ) : null}

            {/* {isNotification ? <MessageItemNotification message={message} /> : null} */}

            {!isEmpty(message.attachments) ? <div className="message-attach">{renderAttachments}</div> : null}
        </>
    );

    if (isDriver) {
        return (
            <div className={styles.driver} key={id}>
                {messageBody}

                <div className="message-date">{messageCreationDate}</div>

                {/* {isUnread ? <div className="message-status">new</div> : null} */}
            </div>
        );
    }

    return (
        <div className={styles.dispatcher} key={id}>
            {isDispatcher ? (
                <div className="message-from">
                    {user.name}

                    <span className="sep">
                        <span className="dot" />
                        <span className="dot" />
                        <span className="dot" />
                    </span>

                    <DispatchersExtension extension={user.extension} />
                </div>
            ) : null}

            {messageBody}

            <div className="message-date">{messageCreationDate}</div>
        </div>
    );
};

export default MessageItem;
