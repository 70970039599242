import {useSelector} from 'react-redux';

import * as selectors from 'widgets/Chat/redux/selectors';

const useChatState = () => ({
    selectedChannelDriverID: useSelector(selectors.getSelectedChannelDriverID),
    unreadMessagesCount: useSelector(selectors.getUnreadMessagesCount),
    chatClientSocketId: useSelector(selectors.getChatClientSocketId),
    channelTruck: useSelector(selectors.getSelectedChannelTruckData),
    channel: useSelector(selectors.getSelectedChannel),
    channels: useSelector(selectors.getAllChannels),

    // NEW ↓
    isMessageStatusSending: useSelector(selectors.checkIsMessageStatusSending),
    isMessageStatusSuccess: useSelector(selectors.checkIsMessageStatusSuccess),
    isMessageStatusFailure: useSelector(selectors.checkIsMessageStatusFailure),
    isMessageStatusIdle: useSelector(selectors.checkIsMessageStatusIdle),
    chatMessageStatus: useSelector(selectors.getMessageStatus),

    isAttachmentsUploading: useSelector(selectors.checkIsAttachmentsUploading),
    openedDriversGroup: useSelector(selectors.getOpenedDriversGroup),
    messageTextToFill: useSelector(selectors.getMessageTextToFill),
    chatDispatcher: useSelector(selectors.getChatDispatcher),
    driversGroups: useSelector(selectors.getDriversGroups),
    searchParams: useSelector(selectors.getSearchParams),
    openedTruck: useSelector(selectors.getOpenedTruck),
    isChatOpen: useSelector(selectors.checkIsChatOpen),
    unreadInfo: useSelector(selectors.getUnreadInfo),
});

export default useChatState;
