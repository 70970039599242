import React from 'react';

import useChatState from 'widgets/Chat/hooks/useChatState';

import styles from './styles.module.scss';

const SendingInfo: React.FC = () => {
    const {chatClientSocketId, isMessageStatusSending, isMessageStatusFailure} = useChatState();

    if (!chatClientSocketId) {
        return null;
    }

    return (
        <>
            {isMessageStatusSending ? (
                <div className={styles.progress}>
                    <div className={styles.icon}>
                        <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                    </div>

                    <div className={styles.text}>Sending...</div>
                </div>
            ) : null}

            {isMessageStatusFailure ? (
                <div className={styles.error}>
                    <div className={styles.icon}>
                        <i className="fa fa-exclamation-triangle" aria-hidden="true" />
                    </div>

                    <div className={styles.text}>Something went wrong, Please try later...</div>
                </div>
            ) : null}
        </>
    );
};

export default SendingInfo;
