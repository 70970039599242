import React from 'react';
import classNames from 'classnames';

import * as filesValidation from 'widgets/Chat/components/MessageForm/utils';
import {SEND_MESSAGE_FORM} from 'widgets/Chat/constants';
import {NormalizedFile} from 'widgets/Chat/types';
import {MessageFormValues} from 'widgets/Chat/types/form';

import Tooltip from 'components/ui/Tooltip';

import formatFileSize from 'utils/files/formatFileSize';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import AttachPreviewImage from './components/AttachPreviewImage';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<MessageFormValues>();

type OwnProps = {
    file: NormalizedFile;
};

const AttachPreview: React.FC<OwnProps> = (props) => {
    const {file} = props;

    const attachments = useGetReduxFormValue(SEND_MESSAGE_FORM, getName('attachments'));

    const {isSomeAttachmentTypeUnsupported} = filesValidation.checkAttachmentsTypeValidation(attachments);
    const {isSomeFileGte50MB} = filesValidation.calculateAttachmentsSizeValidation(attachments);

    const itemClassName = classNames(styles.item, {
        [styles.invalid]: isSomeFileGte50MB || isSomeAttachmentTypeUnsupported,
    });

    return (
        <div className={itemClassName}>
            <AttachPreviewImage file={file} />

            <div className="file-name-tooltip">
                <Tooltip className="btn-tooltip" tooltipContent={`${file.name}`}>
                    <div className="file-name">{file.name}</div>
                </Tooltip>

                {file.size ? <div className="file-size">{formatFileSize(file.size)}</div> : null}
            </div>
        </div>
    );
};

export default AttachPreview;
