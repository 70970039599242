import React from 'react';
import {FixedSizeList as OptimizedList} from 'react-window';
import isEmpty from 'lodash/isEmpty';

import useChatState from 'widgets/Chat/hooks/useChatState';

import DriversGroup from './components/DriversGroup';

import styles from './styles.module.scss';

const DriversGroups: React.FC = () => {
    const {driversGroups} = useChatState();

    const {allTmsUserIDs, byTmsUserID, searchedTmsUserIDs} = driversGroups;

    if (isEmpty(allTmsUserIDs)) {
        return null;
    }

    const driversGroupsRows = (searchedTmsUserIDs || allTmsUserIDs).map((tmsUserID) => {
        const driversGroup = byTmsUserID[tmsUserID];

        return <DriversGroup key={tmsUserID} driversGroup={driversGroup} />;
    });

    return (
        <OptimizedList
            itemCount={driversGroupsRows.length}
            height={window.innerHeight}
            className={styles.scroll}
            itemSize={80}
        >
            {({index, style}) => <div style={style}>{driversGroupsRows[index]}</div>}
        </OptimizedList>
    );
};

export default DriversGroups;
