import sumBy from 'lodash/sumBy';
import {createSelector} from 'reselect';

import {AppState} from 'store';

import {DriverChannel} from 'widgets/Chat/types';

import {ChatState} from '../reducers';

// NEW ↓
export const checkIsMessageStatusSuccess = (state: AppState): boolean => state.chat.messageStatus === 'success';
export const checkIsMessageStatusSending = (state: AppState): boolean => state.chat.messageStatus === 'sending';
export const checkIsMessageStatusFailure = (state: AppState): boolean => state.chat.messageStatus === 'failure';
export const checkIsMessageStatusIdle = (state: AppState): boolean => state.chat.messageStatus === 'idle';

export const getMessageTextToFill = (state: AppState): ChatState['messageTextToFill'] => state.chat.messageTextToFill;
export const getChatClientSocketId = (state: AppState): ChatState['clientSocketId'] => state.chat.clientSocketId;
export const getDriversGroups = (state: AppState): ChatState['driversGroups'] => state.chat.driversGroups;
export const getMessageStatus = (state: AppState): ChatState['messageStatus'] => state.chat.messageStatus;
export const getSearchParams = (state: AppState): ChatState['searchParams'] => state.chat.searchParams;
export const getChatDispatcher = (state: AppState): ChatState['dispatcher'] => state.chat.dispatcher;
export const getOpenedTruck = (state: AppState): ChatState['openedTruck'] => state.chat.openedTruck;
export const getUnreadInfo = (state: AppState): ChatState['unreadInfo'] => state.chat.unreadInfo;
export const checkIsChatOpen = (state: AppState): ChatState['isOpen'] => state.chat.isOpen;

export const getAttachmentsToMessage = (state: AppState): ChatState['openedDriversGroup']['attachmentsToMessage'] =>
    state.chat.openedDriversGroup?.attachmentsToMessage;
export const getOpenedDriversGroup = (state: AppState): ChatState['openedDriversGroup'] =>
    state.chat.openedDriversGroup;
export const checkIsAttachmentsUploading = (state: AppState): boolean =>
    state.chat.openedDriversGroup?.isAttachmentsUploading;

// old
export const getAllChannels = (state: AppState): ChatState['channels'] => state.chat.channels;

export const getSelectedChannelDriverID = (state: AppState): ChatState['selectedChannelData']['driverID'] =>
    state.chat.selectedChannelData.driverID;
export const getSelectedChannelTruckData = (state: AppState): ChatState['selectedChannelData']['truck'] =>
    state.chat.selectedChannelData.truck;

export const getChannelByDriverID = (allChannels, driverID) => {
    if (!allChannels || !driverID) {
        return undefined;
    }

    return allChannels.byDriverID ? allChannels.byDriverID[driverID] : undefined;
};

export const getSelectedChannel = (state: AppState): DriverChannel => {
    const driverID = getSelectedChannelDriverID(state);

    return getChannelByDriverID(state.chat.channels, driverID);
};

export const getUnreadMessagesCount = createSelector([getAllChannels], (allChannels) => {
    return sumBy(Object.values(allChannels.byDriverID), (channel) => channel.unreadMessagesCount);
});
