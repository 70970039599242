import React from 'react';
import classNames from 'classnames';
import head from 'lodash/head';
import last from 'lodash/last';

import {DriversGroup} from 'widgets/Chat/types/group';

import {createDate} from 'utils/dateTime';

import styles from './styles.module.scss';

type OwnProps = {
    driversGroup: DriversGroup;
};

const LastMessage: React.FC<OwnProps> = (props) => {
    const {driversGroup} = props;

    const lastMessage = head(driversGroup?.messages);

    if (!lastMessage) {
        return (
            <div className={styles.empty}>
                <em>press to start chat</em>
            </div>
        );
    }

    const lastMessageContentJSX = () => {
        const lastMessageLastAttachment = last(lastMessage.attachments);
        const lastMessageText = lastMessage.text;

        if (lastMessageLastAttachment) {
            return (
                <>
                    <i className="fa fa-paperclip" aria-hidden="true" /> {lastMessageLastAttachment.originalname}
                </>
            );
        }

        // if (lastMessage?.isNotification) {
        //     return <div className={classNames(styles.notification, 'ellips-text')}>{lastMessageText}</div>;
        // }

        return <div dangerouslySetInnerHTML={{__html: lastMessageText}} />;
    };

    const messageDate = createDate(lastMessage?.createdAt, {fromISO: true}).fullDate;

    return (
        <>
            <div className={classNames(styles.message, 'ellips-text')}>{lastMessageContentJSX()}</div>

            <div className={styles.date}>{messageDate}</div>
        </>
    );
};

export default LastMessage;
