import React from 'react';

import {DriverGroup} from 'widgets/Chat/types/group';

import styles from './styles.module.scss';

type OwnProps = {
    driver: DriverGroup;
    withLink?: boolean;
};

const DriverAvatar: React.FC<OwnProps> = (props) => {
    const {driver, withLink = true} = props;

    const imageSrc = driver?.avatar?.thumb || null;

    const defaultDriverAvatarJSX = (
        <div className={styles.avatar}>
            <i className="fa fa-user" aria-hidden="true" />
        </div>
    );

    if (withLink && imageSrc) {
        return (
            <div className={styles.wrap}>
                <a href={`/web/${imageSrc}`} target="_blank" rel="noreferrer">
                    <img src={`/web/${imageSrc}`} alt={driver.name} title="click to open full size" />
                </a>
            </div>
        );
    }

    return (
        <div className={styles.wrap}>
            {imageSrc ? <img src={`/web/${imageSrc}`} alt={driver.name} /> : defaultDriverAvatarJSX}
        </div>
    );
};

export default DriverAvatar;
