import filter from 'lodash/filter';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import keyBy from 'lodash/keyBy';
import last from 'lodash/last';
import map from 'lodash/map';

import {DriverSearchFormValues} from 'widgets/Chat/types/form';
import {DriversGroup} from 'widgets/Chat/types/group';

export const getDriverFromDriversGroupsByDriverID = (params: {state; driverID: number}) => {
    const {state, driverID} = params;

    const allDrivers = flatMap(state?.driversGroups?.byTmsUserID, 'drivers');
    const driver = find(allDrivers, {tmsEntityID: driverID});

    return driver;
};

export const handleReducerByDriversGroupsReceived = (params: {state; driversGroups: DriversGroup[]}) => {
    const {state, driversGroups} = params;

    const byTmsUserID = keyBy(driversGroups, (group) => group.drivers[0].tmsUserID);
    const allTmsUserIDs = driversGroups.map((group) => group.drivers[0].tmsUserID);

    return {
        ...state,
        driversGroups: {allTmsUserIDs, byTmsUserID},
    };
};

export const handleReducerByLeaveDriversGroup = (params: {state; tmsUserID: number}) => {
    const {state, tmsUserID} = params;

    const lastGroupMessage = last(state.driversGroups.byTmsUserID[tmsUserID].messages);

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            byTmsUserID: {
                ...state.driversGroups.byTmsUserID,
                [tmsUserID]: {
                    ...state.driversGroups.byTmsUserID[tmsUserID],
                    messages: lastGroupMessage ? [lastGroupMessage] : [],
                },
            },
        },
        openedDriversGroup: null,
        messageTextToFill: null,
        openedTruck: null,
    };
};

export const handleReducerByOpenDriversGroupWithMessage = (params: {state; driverID: number; messageText: string}) => {
    const {state, driverID, messageText} = params;

    const driver = getDriverFromDriversGroupsByDriverID({state, driverID});

    if (!driver) {
        return {
            ...state,
        };
    }

    return {
        ...state,
        openedDriversGroup: {...state.openedDriversGroup, tmsUserID: driver.tmsUserID},
        messageTextToFill: messageText,
        isOpen: true,
    };
};

export const handleReducerBySearchParamsReceived = (params: {state; formValues: Partial<DriverSearchFormValues>}) => {
    const {state, formValues} = params;

    if (!formValues.truckNumber) {
        return {
            ...state,
            driversGroups: {
                ...state.driversGroups,
                searchedTmsUserIDs: null,
            },
            searchParams: null,
        };
    }

    const allDrivers = flatMap(state?.driversGroups?.byTmsUserID, 'drivers');
    const filteredDrivers = filter(allDrivers, (item) => item.truck.number.includes(formValues.truckNumber));
    const searchedTmsUserIDs = map(filteredDrivers, (item) => item.tmsUserID);

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            searchedTmsUserIDs,
        },
        searchParams: formValues,
    };
};

export const handleReducerByDriversGroupAllMessagesReceived = (params: {state; messages}) => {
    const {state, messages} = params;

    const openedGroup = state.openedDriversGroup.tmsUserID;

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            byTmsUserID: {
                ...state.driversGroups.byTmsUserID,
                [openedGroup]: {
                    ...state.driversGroups.byTmsUserID[openedGroup],
                    messages,
                },
            },
        },
    };
};
