import React from 'react';

import DropDownCheckbox from 'components/ui/Form/DropDownCheckbox';

import {loadTypesOptions} from './utils';

interface OwnProps {
    name: string;
}

const LoadTypeCheckBoxMulti: React.FC<OwnProps> = (props) => {
    const {name} = props;

    return <DropDownCheckbox name={name} placeholder="Load Type" options={loadTypesOptions} />;
};

export default LoadTypeCheckBoxMulti;
