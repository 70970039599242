import {ErrorInfo} from 'react';
import {push} from 'connected-react-router';

import {
    hideLoader,
    setAppSettings,
    setCurrentUser,
    setPermissions,
    showLoader,
    updateCurrentUser,
    userLogIn,
    userLogOut,
} from 'store/actionCreators';
import {checkIsUserLoggedIn, getUserAccessToken} from 'store/reducers/auth/selectors';
import * as registryActions from 'store/reducers/registry/actions';
import {getCurrentUserEmail} from 'store/reducers/userData/selectors';
import {getErrorHandlers} from 'store/utils';

import {checkGoogleApiScript} from 'services/mapsApi';
import {
    fetchCurrentDispatcher,
    fetchFiles,
    fetchGranted,
    fetchStartSettings,
    logout as logoutRequest,
} from 'services/restapi';

import {chatDisconnect} from 'widgets/Chat/redux/actions/wsActions';

import * as modalActions from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

export const showAlert = (title: any, alertType?: any, onOkText: any = '') => (dispatch, getState) => {
    if (!checkIsUserLoggedIn(getState())) {
        return;
    }

    dispatch(
        modalActions.openModal({
            modalName: commonModalNames.alert,
            data: {
                alertType,
                title,
                onOkText,
            },
        }),
    );
};

export const handleError = (error): any => (dispatch) => {
    const [firstMessageFromData] = Object.values(error?.data?.data || {}).filter(Boolean); // temp solution

    const title = error?.statusText || 'Error';
    const isDefaultError = error?.data?.type === 'DEFAULT';
    const defaultMessage = 'Something went wrong. Please try later...';
    const message = isDefaultError ? error?.data?.message : firstMessageFromData || error?.data?.message;
    const errorMessage = message || defaultMessage;

    getErrorHandlers(
        error,
        () =>
            dispatch(
                modalActions.openModal({
                    modalName: commonModalNames.informationModal,
                    data: {
                        title,
                        rightButtonTitle: 'ok',
                        errorMessage,
                        bodyType: 'ErrorForm',
                        buttonType: 'danger',
                    },
                }),
            ),
        () => dispatch(push(`${window.location.pathname}/not-found`)),
        () =>
            dispatch(
                modalActions.openModal({
                    modalName: commonModalNames.informationModal,
                    data: {
                        title,
                        errorMessage,
                        bodyType: 'ErrorForm',
                        buttonType: undefined,
                    },
                }),
            ),
    );
};

export const login = (authData) => (dispatch) => {
    const token = authData.access_token;

    const requests = [fetchGranted(token), fetchCurrentDispatcher(token), fetchStartSettings(token)];

    Promise.all(requests)
        .then(async ([_, {data: user}, {data: appSettings}]) => {
            dispatch(setPermissions(user.computedPermissions));
            dispatch(setAppSettings(appSettings));
            dispatch(setCurrentUser(user));
            dispatch(userLogIn(authData));

            checkGoogleApiScript();

            // Common data
            dispatch(registryActions.fetchRegistry());
            dispatch(registryActions.fetchTruckEquipment());
            dispatch(registryActions.fetchUserRoleOptions());
            dispatch(registryActions.fetchNetworksBenefits());
            dispatch(registryActions.fetchDefaultTravelOrderPresets());

            const filesParameters = {entity: `dispatchers`, id: `${user?.id}`, type: 'avatar'};
            const avatar = await fetchFiles(filesParameters).then((res: any) => res.data[0]);

            dispatch(updateCurrentUser({avatar}));
        })
        .catch((error) => {
            dispatch(handleError(error));
        });
};

const dispatchLogout = (params: {withReloadPage: boolean} = {withReloadPage: false}) => {
    return function (dispatch, getState) {
        const {withReloadPage} = params;
        const reloadPageByTimeOut = () => {
            const ONE_SECOND = 1000;
            window.setTimeout(() => {
                window.location.reload();
            }, ONE_SECOND);
        };
        dispatch(showLoader());
        const userAuthKey = getUserAccessToken(getState());
        logoutRequest(userAuthKey)
            .catch((error) => {
                console.error('Error on logout: ', error);
            })
            .finally(() => {
                dispatch(chatDisconnect());
                dispatch(userLogOut());
                // we should call this actions for recreate links
                // between local storage and state for userData and userSettings
                dispatch({
                    type: 'persist/REHYDRATE',
                    key: 'userData',
                });
                dispatch({
                    type: 'persist/REHYDRATE',
                    key: 'appSettings',
                });
                // dispatch({
                //     type: 'persist/REHYDRATE',
                //     key: 'userSettings',
                // });
                dispatch({
                    type: 'persist/REHYDRATE',
                    key: 'userPermissions',
                });
                dispatch({
                    type: 'persist/REHYDRATE',
                    key: 'externalParams',
                });
                if (withReloadPage) {
                    reloadPageByTimeOut();
                }
                dispatch(hideLoader());
            });
    };
};

export const logout = () => (dispatch) => {
    dispatch(dispatchLogout());
};

export const logoutWithReload = () => (dispatch) => {
    dispatch(dispatchLogout({withReloadPage: true}));
};

export const reportError = (params: {error: Error; errorInfo: ErrorInfo}) => async (_, getState, {api}) => {
    const email = getCurrentUserEmail(getState());
    const url = window.location.href;

    const message = `
            Error Boundary:

            Url: ${url}
            —

            Email: ${email}
            —

            ErrorMessage: ${params.error.message}
            —

            ComponentStack: ${params.errorInfo.componentStack}
        `;

    const formData = new FormData();

    formData.append('subject', 'Error Boundary');
    formData.append('body', message);

    await api.default.post('/email/support', formData, {
        headers: {'Content-Type': 'multipart/form-data'},
    });
};

type ConfirmParams = {
    title: string;
    type: string;
    body: string;
    okText: string;
    cancelText: string;
    showCancel: boolean;
    okAction?: any;
};

export const showConfirm = (params: ConfirmParams): any => (dispatch) => {
    const defaultParams = {
        title: params.title,
        confType: params.type,
        body: params.body,
        okText: params.okText,
        cancelText: params.cancelText,
        showCancel: true,
    };

    const modalParams = {
        modalName: commonModalNames.confirm,
        data: {
            ...defaultParams,
            ...params,
        },
        handlers: {},
    };
    if (params.okAction) {
        modalParams.handlers = {
            onOk: (parameters) => params.okAction(parameters),
        };
    }

    dispatch(modalActions.openModal(modalParams));
};

export const toggleSendEmail = (params: any): any => {
    return function (dispatch) {
        const {entity} = params;

        if (entity && entity.is_deleted) {
            return;
        }

        dispatch(
            modalActions.openModal({
                modalName: commonModalNames.sendEmail,
                data: {...params},
            }),
        );
    };
};
