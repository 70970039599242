import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import last from 'lodash/last';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import orderBy from 'lodash/orderBy';

import {getUserAccessToken} from 'store/reducers/auth/selectors';

import appConfig from 'config';

import * as selectors from 'widgets/Chat/redux/selectors';
import {WebsocketMessageResponse} from 'widgets/Chat/types/message';

export const transformFileListToRequestBody = (fileList: FileList | null) => {
    const formData = new FormData();

    map(fileList, (file) => formData.append('attachment', file));

    return formData;
};

const transformDriverToRequestBody = (driver) => {
    const {truck, avatar} = driver;

    const truckForMsg = omitBy({id: truck?.id || null, number: truck?.number || null}, (value) => isNull(value));
    const avatarForMsg = omitBy({id: avatar?.id || null, thumb: avatar?.number || null}, (value) => isNull(value));

    return {
        avatar: !isEmpty(avatarForMsg) ? avatarForMsg : null,
        truck: !isEmpty(truckForMsg) ? truckForMsg : null,
        tmsEntityID: driver.tmsEntityID,
        mobilePhone: driver.mobilePhone,
        tmsUserID: driver.tmsUserID,
        language: driver.language,
        isOwner: driver.isOwner,
        name: driver.name,
        type: 'driver',
        id: driver.id,
    };
};

const transformAttachmentsToRequestBody = (attachments) => {
    return map(attachments, (attachment) => attachment.id);
};

export const transformDispatcherMessageToRequestBody = (state, message) => {
    const openedDriversGroup = selectors.getOpenedDriversGroup(state);
    const attachments = selectors.getAttachmentsToMessage(state);
    const chatDispatcher = selectors.getChatDispatcher(state);
    const driversGroups = selectors.getDriversGroups(state);
    const token = getUserAccessToken(state);

    const driversGroup = driversGroups.byTmsUserID[openedDriversGroup.tmsUserID];

    const [driver] = driversGroup.drivers || [];

    return {
        meta: {
            serverPrefix: appConfig.REACT_APP_PREFIX,
            token,
        },
        message: {
            attachments: transformAttachmentsToRequestBody(attachments),
            driver: transformDriverToRequestBody(driver),
            groupID: driversGroup.id || null,
            dispatcherID: chatDispatcher.id,
            text: message.text,
        },
    };
};

export const handleReducerByInitMessagesReceived = (params: {state; messages; tmsUserID: number}) => {
    const {state, messages, tmsUserID} = params;

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            byTmsUserID: {
                ...state.driversGroups.byTmsUserID,
                [tmsUserID]: {...state.driversGroups.byTmsUserID[tmsUserID], messages},
            },
        },
    };
};

export const handleReducerByNewDispatcherMessageReceived = (params: {state; message: WebsocketMessageResponse}) => {
    const {state, message} = params;

    const {id, groupID, driverTmsUserID} = message || {};

    if (!id && (!groupID || driverTmsUserID)) {
        return {...state, messageStatus: 'failure'};
    }

    const existingGroup: any = Object.values(state.driversGroups.byTmsUserID).find((group: any) => {
        const [driver] = group.drivers || [];

        return group.id === groupID || driverTmsUserID === driver.tmsUserID;
    });

    if (!existingGroup) {
        return {...state, messageStatus: 'failure'};
    }

    const [driver] = existingGroup.drivers || [];
    const isGroupOpenNow = state.openedDriversGroup?.tmsUserID === driver.tmsUserID;

    const orderGroups = (groups) => orderBy(groups, ['lastMessage.createdAt'], ['desc']);

    if (isGroupOpenNow) {
        const byTmsUserID = {
            ...state.driversGroups.byTmsUserID,
            [driver.tmsUserID]: {...existingGroup, messages: [...existingGroup.messages, message]},
        };

        const latestMessages = Object.values(byTmsUserID).map((item: any) => {
            const [itemDriver] = item.drivers || [];
            return {tmsUserID: itemDriver.tmsUserID, lastMessage: last(item.messages)};
        });

        const groupWithEmptyMessages = latestMessages
            .filter((item) => isEmpty(item.lastMessage))
            .map((itm) => itm.tmsUserID);
        const groupWithMessages = latestMessages.filter((item) => !isEmpty(item.lastMessage));

        const orderedGroupWithMessages = orderGroups(groupWithMessages).map((itm) => itm.tmsUserID);

        return {
            ...state,
            messageStatus: 'success',
            driversGroups: {
                ...state.driversGroups,
                byTmsUserID,
                allTmsUserIDs: [...orderedGroupWithMessages, ...groupWithEmptyMessages],
            },
        };
    }

    const byTmsUserID = {
        ...state.driversGroups.byTmsUserID,
        [driver.tmsUserID]: {...existingGroup, messages: [message]},
    };

    const latestMessages = Object.values(byTmsUserID).map((item: any) => {
        const [itemDriver] = item.drivers || [];
        return {tmsUserID: itemDriver.tmsUserID, lastMessage: last(item.messages)};
    });

    const groupWithEmptyMessages = latestMessages
        .filter((item) => isEmpty(item.lastMessage))
        .map((itm) => itm.tmsUserID);
    const groupWithMessages = latestMessages.filter((item) => !isEmpty(item.lastMessage));

    const orderedGroupWithMessages = orderGroups(groupWithMessages).map((itm) => itm.tmsUserID);

    return {
        ...state,
        messageStatus: 'success',
        driversGroups: {
            ...state.driversGroups,
            byTmsUserID,
            allTmsUserIDs: [...orderedGroupWithMessages, ...groupWithEmptyMessages],
        },
    };
};

export const handleReducerByNewDriverMessageReceived = (params: {state; message: WebsocketMessageResponse}) => {
    const {state, message} = params;

    const {groupID, driverTmsUserID} = message || {};

    const existingGroup: any = Object.values(state.driversGroups.byTmsUserID).find((group: any) => {
        const [driver] = group.drivers || [];

        return group.id === groupID || driverTmsUserID === driver.tmsUserID;
    });

    if (!existingGroup) {
        return {...state};
    }

    const [driver] = existingGroup.drivers || [];
    const isGroupOpenNow = state.openedDriversGroup?.tmsUserID === driver.tmsUserID;

    const orderGroups = (groups) => orderBy(groups, ['lastMessage.createdAt'], ['desc']);

    if (isGroupOpenNow) {
        const byTmsUserID = {
            ...state.driversGroups.byTmsUserID,
            [driver.tmsUserID]: {...existingGroup, messages: [...existingGroup.messages, message]},
        };

        const latestMessages = Object.values(byTmsUserID).map((item: any) => {
            const [itemDriver] = item.drivers || [];
            return {tmsUserID: itemDriver.tmsUserID, lastMessage: last(item.messages)};
        });

        const groupWithEmptyMessages = latestMessages
            .filter((item) => isEmpty(item.lastMessage))
            .map((itm) => itm.tmsUserID);
        const groupWithMessages = latestMessages.filter((item) => !isEmpty(item.lastMessage));

        const orderedGroupWithMessages = orderGroups(groupWithMessages).map((itm) => itm.tmsUserID);

        return {
            ...state,
            driversGroups: {
                ...state.driversGroups,
                byTmsUserID,
                allTmsUserIDs: [...orderedGroupWithMessages, ...groupWithEmptyMessages],
            },
        };
    }

    const byTmsUserID = {
        ...state.driversGroups.byTmsUserID,
        [driver.tmsUserID]: {...existingGroup, messages: [message]},
    };

    const latestMessages = Object.values(byTmsUserID).map((item: any) => {
        const [itemDriver] = item.drivers || [];
        return {tmsUserID: itemDriver.tmsUserID, lastMessage: last(item.messages)};
    });

    const groupWithEmptyMessages = latestMessages
        .filter((item) => isEmpty(item.lastMessage))
        .map((itm) => itm.tmsUserID);
    const groupWithMessages = latestMessages.filter((item) => !isEmpty(item.lastMessage));

    const orderedGroupWithMessages = orderGroups(groupWithMessages).map((itm) => itm.tmsUserID);

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            byTmsUserID,
            allTmsUserIDs: [...orderedGroupWithMessages, ...groupWithEmptyMessages],
        },
    };
};

export const handleReducerByMoreMessagesReceived = (params: {state; messages; tmsUserID: number}) => {
    const {state, messages, tmsUserID} = params;

    return {
        ...state,
        driversGroups: {
            ...state.driversGroups,
            byTmsUserID: {
                ...state.driversGroups.byTmsUserID,
                [tmsUserID]: {
                    ...state.driversGroups.byTmsUserID[tmsUserID],
                    messages: [...messages, ...state.driversGroups.byTmsUserID[tmsUserID].messages],
                },
            },
        },
    };
};
