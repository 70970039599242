import React from 'react';

import * as filesValidation from 'widgets/Chat/components/MessageForm/utils';
import {MessageFormValues} from 'widgets/Chat/types/form';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<MessageFormValues>();

type OwnProps = {
    form: string;
};

const Alert: React.FC<OwnProps> = (props) => {
    const {form} = props;

    const attachments = useGetReduxFormValue(form, getName('attachments'));

    const {isSomeAttachmentTypeUnsupported} = filesValidation.checkAttachmentsTypeValidation(attachments);
    const {isSomeFileGte50MB} = filesValidation.calculateAttachmentsSizeValidation(attachments);

    if (!isSomeFileGte50MB && !isSomeAttachmentTypeUnsupported) {
        return null;
    }

    const unsupportedSizeMessage = isSomeFileGte50MB ? 'File is too big (max 50 MB)' : null;
    const unsupportedTypeMessage = isSomeAttachmentTypeUnsupported
        ? 'Unsupported type file, please choose another one (use only jpeg/jpg/png/bmp/pdf/txt)'
        : null;

    return (
        <div className={styles.container}>
            <div className={styles.icon}>
                <i className="fa fa-info-circle" aria-hidden="true" />
            </div>

            <div className={styles.text}>{unsupportedSizeMessage || unsupportedTypeMessage}</div>
        </div>
    );
};

export default Alert;
