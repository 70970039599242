export const WS_CHAT_DISPATCHER_MESSAGE_RECEIVED = 'CHAT/WS_DISPATCHER_MESSAGE_RECEIVED' as const;
export const WS_CHAT_DRIVER_MESSAGE_RECEIVED = 'CHAT/WS_DRIVER_MESSAGE_RECEIVED' as const;

// old
export const WEB_SOCKET_CHAT_MARK_AS_READ_BY_CURRENT_DISPATCHER = 'WEB_SOCKET_CHAT_MARK_AS_READ_BY_CURRENT_DISPATCHER' as const;
export const WEB_SOCKET_CHAT_MARK_AS_READ_BY_EVERY_DISPATCHER = 'WEB_SOCKET_CHAT_MARK_AS_READ_BY_EVERY_DISPATCHER' as const;
export const WEB_SOCKET_CHAT_MARK_LOCAL_MESSAGES_AS_READ = 'WEB_SOCKET_CHAT_MARK_LOCAL_MESSAGES_AS_READ' as const;

export const WEB_SOCKET_CHAT_MORE_CHANNEL_MESSAGES_RECEIVED = 'WEB_SOCKET_CHAT_MORE_CHANNEL_MESSAGES_RECEIVED' as const;
export const WEB_SOCKET_CHAT_JOIN_SERVER_BY_DISPATCHER = 'WEB_SOCKET_CHAT_JOIN_SERVER_BY_DISPATCHER' as const;
export const WEB_SOCKET_CHAT_GET_MORE_CHANNEL_MESSAGES = 'WEB_SOCKET_CHAT_GET_MORE_CHANNEL_MESSAGES' as const;
export const WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE_ERROR = 'WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE_ERROR' as const;
export const WEB_SOCKET_CHAT_CONNECTION_RECEIVED = 'WEB_SOCKET_CHAT_CONNECTION_RECEIVED' as const;
export const WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE = 'WEB_SOCKET_CHAT_SEND_CHAT_MESSAGE' as const;

export const WEB_SOCKET_CHAT_CLEAR_SOCKET_ID = 'WEB_SOCKET_CHAT_CLEAR_SOCKET_ID' as const;
export const WEB_SOCKET_CHAT_SET_SOCKET_ID = 'WEB_SOCKET_CHAT_SET_SOCKET_ID' as const;
export const WEB_SOCKET_CHAT_DISCONNECT = 'WEB_SOCKET_CHAT_DISCONNECT' as const;
