import React, {useState} from 'react';
import DOMPurify from 'dompurify';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import {Field, Form, InjectedFormProps, reduxForm} from 'redux-form';

import * as filesValidation from 'widgets/Chat/components/MessageForm/utils';
import {SEND_MESSAGE_FORM} from 'widgets/Chat/constants';
import useChatActions from 'widgets/Chat/hooks/useChatActions';
import useChatState from 'widgets/Chat/hooks/useChatState';
import {MessageFormValues} from 'widgets/Chat/types/form';

import Button from 'components/ui/Buttons/Button';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import Alert from './components/Alert';
import AttachmentsUpload from './components/AttachmentsUpload';
import SendingInfo from './components/SendingInfo';
import TipTapEditor from './components/TipTapEditor';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<MessageFormValues>();

const MessageForm: React.FC<InjectedFormProps> = (props) => {
    const {handleSubmit, form} = props;

    const {chatClientSocketId, isMessageStatusSending, isAttachmentsUploading} = useChatState();
    const attachments = useGetReduxFormValue(form, getName('attachments'));
    const {sendMessageByDispatcherToDriversGroup} = useChatActions();
    const [messageText, setMessageText] = useState('');

    const handleSubmitMessage = () => {
        if (isEmpty(attachments) && isEmpty(messageText)) {
            return;
        }

        const message = {
            text: DOMPurify.sanitize(trim(messageText)),
        };

        sendMessageByDispatcherToDriversGroup({message});
    };

    const {isSomeAttachmentTypeUnsupported} = filesValidation.checkAttachmentsTypeValidation(attachments);
    const {isSomeFileGte50MB} = filesValidation.calculateAttachmentsSizeValidation(attachments);

    const isSubmitDisabled =
        isSomeAttachmentTypeUnsupported ||
        !chatClientSocketId ||
        isSomeFileGte50MB ||
        isMessageStatusSending ||
        isAttachmentsUploading;

    const isEditorDisabled =
        isSomeFileGte50MB || isSomeAttachmentTypeUnsupported || !chatClientSocketId || isMessageStatusSending;

    return (
        <Form className={styles.form} onSubmit={handleSubmit(handleSubmitMessage)}>
            <SendingInfo />

            <div className="bottom-hr" />

            <Alert form={form} />

            <div className={styles.body}>
                <Field
                    disabled={!chatClientSocketId || isMessageStatusSending}
                    existingFilesKey="attachments"
                    component={AttachmentsUpload}
                    name={getName('attachments')}
                    type="file"
                />

                <div className="d-flex align-items-end position-relative">
                    <TipTapEditor
                        isEditorDisabled={isEditorDisabled}
                        setMessageText={setMessageText}
                        onSubmitByEnter={handleSubmit}
                        messageText={messageText}
                    />

                    <Button
                        className={styles.button__search}
                        disabled={isSubmitDisabled}
                        buttonIcon="paper-plane"
                        colorTheme="grey"
                        buttonSize="icon"
                        type="submit"
                    />
                </div>
            </div>
        </Form>
    );
};

export default reduxForm({form: SEND_MESSAGE_FORM})(MessageForm);
